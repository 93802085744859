// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import {
  Firestore,
  getFirestore,
  initializeFirestore,
  persistentLocalCache,
  SetOptions,
} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';
import { 
  initializeAppCheck, 
  ReCaptchaV3Provider,
} from 'firebase/app-check';

// eslint-disable-next-line prettier/prettier
declare global {
  // eslint-disable-next-line no-var
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Enable debug token in development ONLY
if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_FIREBASE_APP_CHECK_DEBUG_TOKEN) {
  // This will only run in development, never in production
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_FIREBASE_APP_CHECK_DEBUG_TOKEN;
}

// Initialize App Check with reCAPTCHA v3
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_SITE_KEY as string),
  isTokenAutoRefreshEnabled: true
});

const firestore = initializeFirestore(app, {
  localCache: persistentLocalCache({}),
});

const auth = getAuth(app);
const database = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);

export { auth, database, storage, functions, appCheck };
