import {
  getFunctions,
  httpsCallableFromURL,
  type HttpsCallableOptions,
} from 'firebase/functions';
import { app } from './firebase';

// Initialize Firebase Functions with App Check enforcement
const functions = getFunctions();

// Wrapper function to create callable functions with App Check
export const createCallableFunction = (
  endpoint: string,
  options?: HttpsCallableOptions,
) => {
  return httpsCallableFromURL(functions, endpoint, {
    ...options,
    // Enforce App Check for all function calls
    limitedUseAppCheckTokens: true,
  });
};

export { functions };
