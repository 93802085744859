/* eslint-disable @typescript-eslint/no-explicit-any */
import { getFunctions, httpsCallableFromURL } from 'firebase/functions';
import React from 'react';
import { firebaseCall } from '../CONSTS/ENDPOINTS';
import { ActiveMarketPlace } from '../../stateManagement/ActiveMarketPlace';
import { type IAEDeliveryOptions, type IAEProduct } from '../TYPES';
import { createCallableFunction } from '../../config/functions';

const functions = getFunctions();
const useAEProductDetailAndShipping = () => {
  // getShippingCostFrontEnd

  const [loading, setLoading] = React.useState(false);
  const [deliveryOptions, setDeliveryOptions] = React.useState<
    // eslint-disable-next-line prettier/prettier
    IAEDeliveryOptions[] | null
  >(null);

  const [aeDetailProduct, setAEdetailProduct] =
    React.useState<IAEProduct | null>(null);

  const getShippingCost = async (productId: string) => {
    try {
      setLoading(true);
      const get = createCallableFunction(
        firebaseCall('getShippingCostFrontEnd'),
      );

      const _shippingCost = (await get({
        productId,
        region: ActiveMarketPlace(),
      })) as { data: { deliveryOptions: any, product: any } };
      setDeliveryOptions(
        _shippingCost?.data?.deliveryOptions as IAEDeliveryOptions[],
      );
      setAEdetailProduct(_shippingCost?.data?.product as IAEProduct);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  return { getShippingCost, deliveryOptions, aeDetailProduct, loading };
};

export default useAEProductDetailAndShipping;
